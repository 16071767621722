<template>
  <main class="upcoming padded">
    <div style="position: relative;">
      <h2>Upcoming sessions</h2>
      
      <div class="scrollbox">
        <div v-for="session in upcomingSessions" :key="session.id" class="session">
          <button class="dots" @click.stop="openContextMenu(session, $event)" :data-sid="session.id"></button>
          <img class="thumbnail" :src="(session.webRoot ? backblazeBlobRoot : blobRoot) + session.iconUrl">
          <div class="details">
            <div class="title">{{session.id}} - {{session.name}}</div>
            <div class="status">{{formatSessionStatus(session.status)}}</div>
            <div class="date" v-if="session.startDate && session.endDate">{{formatDates(new Date(session.startDate), new Date(session.endDate), $store.getters.timeZone)}}
            </div>          
            <div class="date" v-else>No dates</div>
            <table>
              <tr>
                <td><img src="../assets/participants.svg"></td>
                <td><span>{{session.userCount}}</span></td>
                <td><span class="light">Learners</span></td>
                <td><img src="../assets/clock.svg"></td>
                <td><span>{{formatWorkflowStatus(session.workflow)}}</span></td>
              </tr>
            </table>
            <div class="links">

              <img v-if="$store.getters.isAdmin || $store.getters.isOrgAdmin" src="../assets/writing.svg">
              <img v-else-if="playbookUrl(session.experienceId)" src="../assets/playbook.png">
              <a v-if="$store.getters.isAdmin || $store.getters.isOrgAdmin" :href="`/dashboard2/${session.id}?testMode=true`">Test</a>
              <a v-else-if="playbookUrl(session.experienceId)" :href="playbookUrl(session.experienceId)">Playbook</a>
              <!-- <img src="../assets/logo.png"> <a href="#">Prepare session</a> -->
              <button v-if="$store.getters.isAdmin || $store.getters.isOrgAdmin" class="white" @click="$router.push('sessions2/' + session.id + '/manage')">Manage Devices</button>
              <button v-else @click="$router.push('dashboard2/' + session.id)">Open</button>
            </div>
          </div>
        </div>
        <div class="session" v-if="!upcomingSessions.length" style="text-align: center;"><span style="padding-top:59px; width: 100%; color: #a2a2a2; font-size: 20px;">There's nothing here, yet</span></div>
      </div>
    </div>
    
    <div style="position: relative;">
      <div class="tabs" v-if="!$store.getters.isAdmin && !$store.getters.isOrgAdmin">
        <span class="tab" :class="{active: tab == 'requests'}" @click="tab = 'requests';"><span>Requests</span></span>
        <span class="tab" :class="{active: tab == 'practice'}" @click="tab = 'practice';"><span>Practice</span></span>
        <span class="tab" :class="{active: tab == 'previous'}" @click="tab = 'previous';"><span>Past sessions</span></span>
      </div>
      <div v-else>
        <h2>Requests</h2>
      </div>
      <button class="white schedule" @click="addSession"><img src="../assets/calendar.svg" style="height:1.4em; vertical-align:middle; margin-top:-2px; margin-right:4px;"> Schedule session</button>
      <div class="scrollbox" v-if="tab == 'requests'">
        <div v-for="session in requests" :key="session.id" class="session request" :class="[formatWorkflowStatus(session.workflow).toLowerCase()]">   
          <div v-if="session.workflowMessage" class="message">
            <img src="../assets/message.svg" />
            <span>{{session.workflowMessage}}</span>
          </div>
          <button class="dots" @click.stop="openContextMenu(session, $event)" :data-sid="session.id"></button>
          <button v-if="$store.getters.isAdmin || $store.getters.isOrgAdmin" class="white admin-button" @click="requestClicked(session)">{{session.workflow == 3 ? 'Prepare' : 'Review'}}</button>
          <div class="details" :class="{warning: dateWarning(session)}">            
            <div class="title">{{session.id}} - {{session.name}}</div>
            <div class="date">              
              <span v-if="session.startDate">{{formatDate(new Date(session.startDate), $store.getters.timeZone)}}</span>
              <span v-else>No dates</span>
              <img v-if="dateWarning(session)" src="../assets/alert_orange.svg" title="Coming up soon" />
            </div>
            <div class="workflow" :class="formatWorkflowStatus(session.workflow).toLowerCase()">{{formatWorkflowStatus(session.workflow)}}</div>            
          </div>
        </div>
        <div class="session past" v-if="!requests.length" style="text-align: center;"><span style="padding-top:59px; width: 100%; color: #a2a2a2; font-size: 20px;">There's nothing here, yet</span></div>
      </div>
      <div class="scrollbox" v-else>
        <div v-for="session in professorFilteredSessions" :key="session.id" class="session past" :class="{practice: session.practice}">
          <button class="dots" @click.stop="openContextMenu(session, $event)" :data-sid="session.id"></button>
          <img class="thumbnail" :src="(session.webRoot ? backblazeBlobRoot : blobRoot) + session.iconUrl">
          <div class="details">
            <div class="title">{{session.id}} - {{session.name}}</div>
            <div class="status">{{session.practice ? 'Practice - ' : ''}}{{formatSessionStatus(session.status)}}</div>
            <div class="date" v-if="session.startDate && session.endDate">{{formatDates(new Date(session.startDate), new Date(session.endDate), $store.getters.timeZone) + ` ${toTimeZone(new Date(session.startDate), $store.getters.timeZone).getFullYear()}`}}</div>                
            <div class="date" v-else>No dates</div>
            <table>
              <tr>
                <td><img src="../assets/participants.svg"></td>
                <td><span>{{session.userCount}}</span></td>
                <td><span class="light">Learners</span></td>
                <td><img src="../assets/clock.svg"></td>
                <td><span>{{formatWorkflowStatus(session.workflow)}}</span></td>
              </tr>              
            </table>
            <div class="links">
              <img v-if="playbookUrl(session.experienceId)" src="../assets/playbook.png"> <a v-if="playbookUrl(session.experienceId)" :href="playbookUrl(session.experienceId)">Playbook</a>
              <button class="white" @click="$router.push('dashboard2/' + session.id)">Open</button>
            </div>
          </div>
        </div>
        <center><button class="white" style="height: 30px;" v-if="tab == 'previous' && loadmore" :disabled="loading" @click="loadMorePastSessions">Load more</button></center>
        <div class="session past" v-if="!professorFilteredSessions.length" style="text-align: center;"><span style="padding-top:59px; width: 100%; color: #a2a2a2; font-size: 20px;">There's nothing here, yet</span></div>
      </div>
    </div>

    <div class="context-menu" v-if="selectedSession" ref="contextMenu" v-show="contextMenuOpen && !loading" :style="`position: fixed; right: ${window.innerWidth - contextX}px; top: ${contextY}px;`">      
      <a href="#" v-if="selectedSession.workflow == 4" @click.prevent="openRow" ><img src="../assets/preview-icon.svg" style="width:24px;">{{selectedSession.status == 0 ? 'Open' : 'Join'}}</a>      
      <a href="#" v-if="selectedSession.workflow == 4 && ($store.getters.isAdmin || $store.getters.isOrgAdmin)" @click.prevent="markRowNotReady" ><img src="../assets/updated.svg" style="width:24px;">Mark not ready</a>      
      <a href="#" @click.prevent="editRow" ><img src="../assets/edit.svg">Edit</a> 
      <a href="#" @click.prevent="copyRow" ><img src="../assets/duplicate.svg">Duplicate</a> 
      <a href="#" @click.prevent="deleteRow"><img src="../assets/delete.svg">Delete</a>
    </div> 

    <ScheduleSession v-show="scheduleSessionModal" ref="editor" @close="scheduleSessionModal = false;" @created="sessionCreated" @updated="sessionUpdated" :headerTitle="`${selectedSession && selectedSession.id ? 'Edit' : 'Schedule'} session`" />

    <Modal2 v-if="sessionScheduledModal" @close="sessionScheduledModal = false;">      
      <div slot="body" style="width: 336px; text-align:center;">
        <img style="margin-top:74px;" src="../assets/mail.svg">
        <div>
          <div style="font-size:20px; padding:50px 0 25px 0;">Great!</div>          
          
          <div v-if="$store.getters.isAdmin || $store.getters.isOrgAdmin">
            The session was created successfully.             
          </div>
          <div v-else>
            Your session has been created successfully.
            <br>
            An admin will be notified to prepare the session.
          </div>
        </div>
      </div>
      <div slot="footer">
        <button class="white right" @click="sessionScheduledModal = false;">Continue</button>
      </div>
    </Modal2>

    <SessionWorkflow v-if="sessionWorkflowModal" :session="selectedSession" @close="sessionWorkflowModal = false;" @updated="sessionWorkflowUpdated" />

    <Snackbar ref="snackbar" /> 

  </main>
</template>

<script>
import axios from 'axios'
import { formatSessionStatus, formatWorkflowStatus, formatDates, formatDate, toTimeZone } from '@/utils.js'
import Modal2 from '../components/Modal2.vue'
import ScheduleSession from '../components/ScheduleSession.vue'
import SessionWorkflow from '../components/SessionWorkflow.vue'
//import {Roles} from '@/roles.js'
import Snackbar from '@/components/Snackbar.vue'

export default {
  name: 'Upcoming',
  data: function(){
    return {        
      blobRoot: process.env.VUE_APP_PUBLIC_BLOB,  
      backblazeBlobRoot: process.env.VUE_APP_PUBLIC_BLOB_BACKBLAZE,
      upcomingSessions: [],
      pastSessions: [],
      requests: [],
      practiceSessions: [],
      loading: false,
      error: undefined,
      contextMenuOpen: false,
      contextX: 0,
      contextY: 0,
      selectedSession: undefined,
      scheduleSessionModal: false,
      sessionScheduledModal: false,
      sessionWorkflowModal: false,
      tab: 'requests',
      tickerInterval: null,
      loadmore: false
    }
  },
  computed: {        
    professorFilteredSessions(){
      return this.tab == 'practice' 
        ? this.practiceSessions
        : this.pastSessions
    } 
  },
  methods: { 
    playbookUrl(experienceId){
      let url = this.$store.state.drupalExperiences.find(e => e.id == this.$store.state.experiences.find(x => x.id == experienceId)?.cmsId)?.playbook_link
      return url ? `${process.env.VUE_APP_PORTAL_URL}${url}` : null
    },
    requestClicked(session){
      if(!this.$store.getters.isAdmin && !this.$store.getters.isOrgAdmin)
        return

      if(session.workflow == 3){
        this.$router.push({name: 'Session2Manage', params: {id: session.id}})
      }
      else{
        this.selectedSession = session
        this.sessionWorkflowModal = true
      }
    },
    sessionWorkflowUpdated(status, reason){
      this.selectedSession.workflow = status
      this.selectedSession.workflowMessage = reason
      this.sessionWorkflowModal = false
      this.$refs.snackbar.show('Request ' + (status == 3 ? 'approved' : 'rejected'))
    },
    dateWarning(session){
      if(!session.startDate)
        return false
      var nextWeek = new Date();
      nextWeek.setDate(nextWeek.getDate() + 7)       
      var sessionDate = new Date(session.startDate)
      return sessionDate < nextWeek
    },
    toTimeZone(d, tz){ return toTimeZone(d,tz) },
    formatDate(d, tz){ return formatDate(d,tz) },
    formatDates(d1,d2, tz){ return formatDates(d1,d2,tz) },
    formatWorkflowStatus(status){
      return formatWorkflowStatus(status)
    },
    yearIfNeeded(startDate){
      if(!startDate)
        return ''
      let year = this.toTimeZone(new Date(startDate), this.$store.getters.timeZone).getFullYear()
      let currentYear = this.toTimeZone(new Date(), this.$store.getters.timeZone).getFullYear()
      return (year != currentYear) ? `, ${year}` : ''
    },
    // async setWorkflow(id, status){
    //   try{
    //     this.loading = true
    //     this.$nprogress.start()    
    //     await axios({ url: `sessions/${id}/setworkflow?status=${status}`, method: "POST" })
    //     // let request = this.requests.find(r => r.id == id)
    //     // if(request){        
    //     //   request.workflow = status  
    //     //   if(status == 2 && (new Date(request.startDate) > new Date())){
    //     //     this.upcomingSessions.unshift(request)    
    //     //   }
    //     // }       
    //     await this.getSessions()
    //   }
    //   catch(err){
    //     this.$refs.snackbar.show(err?.response?.data ?? err)
    //   }
    //   finally{
    //     this.loading = false
    //     this.$nprogress.done()
    //     this.contextMenuOpen = false
    //   }
    // },
    async deleteRow(){
      if(confirm(`Delete session with id ${this.selectedSession.id}?`)){
        await axios({ url: `sessions`, data: [this.selectedSession.id], method: 'DELETE' })    

        let indexOfItem = this.upcomingSessions.findIndex(s => s.id == this.selectedSession.id)        
        if(indexOfItem >= 0){
          this.upcomingSessions.splice(indexOfItem, 1)        
        }  
        
        indexOfItem = this.requests.findIndex(s => s.id == this.selectedSession.id)  
        if(indexOfItem >= 0){  
          this.requests.splice(indexOfItem, 1)      
        }   
        
        indexOfItem = this.pastSessions.findIndex(s => s.id == this.selectedSession.id)  
        if(indexOfItem >= 0){  
          this.pastSessions.splice(indexOfItem, 1)      
        } 

        indexOfItem = this.practiceSessions.findIndex(s => s.id == this.selectedSession.id)  
        if(indexOfItem >= 0){  
          this.practiceSessions.splice(indexOfItem, 1)      
        } 

        this.contextMenuOpen = false        
        this.$refs.snackbar.show('Session deleted successfully')
      }
    },  
    async openRow(){           
      this.$router.push('dashboard2/' + this.selectedSession.id)
      this.contextMenuOpen = false
    },
    async markRowNotReady(){
      try{
        this.loading = true
        this.$nprogress.start()    
        await axios({ url: `sessions/${this.selectedSession.id}/setworkflow?status=${3}`, method: "POST" })        
        await this.getSessions()        
        this.$refs.snackbar.show("Session moved back to Approved requests")
      }
      catch(err){
        this.$refs.snackbar.show(err?.response?.data ?? err)
      }
      finally{
        this.loading = false
        this.$nprogress.done()
        this.contextMenuOpen = false
      }
    },
    async editRow(){           
      let tmp = JSON.parse(JSON.stringify(this.selectedSession))              
      this.$refs.editor.edit(tmp)
      this.scheduleSessionModal = true
      this.contextMenuOpen = false
    },
    async copyRow(){           
      let tmp = JSON.parse(JSON.stringify(this.selectedSession)) 
      this.selectedSession = null             
      this.$refs.editor.create(tmp)
      this.scheduleSessionModal = true
      this.contextMenuOpen = false
    },
    sessionUpdated(session){
      let indexOfItem = this.upcomingSessions.findIndex(s => s.id == session.id)        
      if(indexOfItem >= 0){
       this.$set(this.upcomingSessions, indexOfItem, session)        
      }  

      indexOfItem = this.requests.findIndex(s => s.id == session.id)    
      if(indexOfItem >= 0){
        this.$set(this.requests, indexOfItem, session)        
      }   
      
      indexOfItem = this.pastSessions.findIndex(s => s.id == session.id)    
      if(indexOfItem >= 0){
        this.$set(this.pastSessions, indexOfItem, session)        
      }  

      indexOfItem = this.practiceSessions.findIndex(s => s.id == session.id)    
      if(indexOfItem >= 0){
        this.$set(this.practiceSessions, indexOfItem, session)        
      }        
      
      this.scheduleSessionModal = false
      this.$refs.snackbar.show('Session updated successfully')
    },
    addSession(){
      this.selectedSession = null
      this.$refs.editor.create()
      this.scheduleSessionModal = true
    },
    sessionCreated(s){     
      if(s.practice && !this.$store.getters.isAdmin && !this.$store.getters.isOrgAdmin){  // practice sessions for profs have their separate tab
        this.practiceSessions.push(s)
      }
      else{
        if(!s.startDate)
          this.requests.push(s)
        else{
          let index = this.requests.findIndex((r,idx) => s.startDate >= r.startDate && ((idx == this.requests.length-1) || this.requests[idx+1].startDate >= s.startDate) || !this.requests[idx+1].startDate)
          if(index >= 0)
            this.requests.splice(index+1, 0, s)
          else
            this.requests.unshift(s)
        }
      }
      this.scheduleSessionModal = false
      this.sessionScheduledModal = true
    },
    async loadMorePastSessions(){
      try{
        this.loading = true
        this.$nprogress.start() 
      
        let last = this.pastSessions.findLast(x => x)
        if(last){
          let resp = await axios.get(`sessions/past?lastDate=${encodeURIComponent(last.startDate)}&lastId=${last.id}`)
          resp.data.forEach(s => {
            this.pastSessions.push(s)
          });
          if(resp.data.length < 1)
            this.loadmore = false
        }
      }      
      catch(err){
        this.$refs.snackbar.show(err?.response?.data ?? err)
      }
      finally{
        this.loading = false
        this.$nprogress.done()
      }
    },
    async getSessions(loadPastSessions){     
          let resp = await axios({ url: "sessions/upcoming?filter=4" }) 
          this.upcomingSessions = resp.data
          resp = await axios.get(`sessions/requests?includePractice=${this.$store.getters.isAdmin || this.$store.getters.isOrgAdmin}`) 
          this.requests = resp.data
        
          if(!this.$store.getters.isAdmin && !this.$store.getters.isOrgAdmin){
            resp = await axios.get("sessions/practice")
            this.practiceSessions = resp.data

            if(loadPastSessions){
              resp = await axios({ url: "sessions/past" }) 
              this.pastSessions = resp.data
              this.loadmore = resp.data.length == 10 // TODO ezt megnézni hogy mi a kezdeti feltétel, ha 10-et töltünk be akkor ha kaptunk annyit akkor feltételezhetjük hogy van még. ha kevesebbt csak, pl 9, akkor már nincs több
            }
          }        
    },
    formatSessionStatus(status){
      return formatSessionStatus(status)
    },
    openContextMenu(session, ev){      
      this.selectedSession = session;
      this.contextX = ev.clientX;
      this.contextY = ev.clientY;
      this.contextMenuOpen = true;
    },
    closeContextMenu(e){
      const withinBoundaries = e.composedPath().includes(this.$refs.contextMenu)
      if(this.contextMenuOpen && (e.keyCode == 27 || (e.keyCode == undefined && !withinBoundaries)))
        this.contextMenuOpen = false;
    }
  },  
  watch: {
    scheduleSessionModal: function(){      
      document.querySelector('.schedule-session .modal2-body').scrollTop = 0      
    }      
  },
  components: {
    Modal2,
    ScheduleSession,
    Snackbar,
    SessionWorkflow
  },
  async mounted() {
    this.window = window
    try{
      this.$nprogress.start()
      this.loading = true
      await this.getSessions(true)
    }
    finally{
      this.loading = false
      this.$nprogress.done()
      this.$store.state.showLoader = false
    }
    window.addEventListener("keyup", this.closeContextMenu, false);
    window.addEventListener("click", this.closeContextMenu, false);
  },
  created() {
    this.tickerInterval = setInterval(() => this.getSessions(), 60000)     
  },
  beforeDestroy: function(){
    if(this.tickerInterval)
      clearInterval(this.tickerInterval)
    window.removeEventListener("keyup", this.closeContextMenu, false);
    window.removeEventListener("click", this.closeContextMenu, false);
  }
}
</script>

<style lang="scss">

main.upcoming{
  display: grid;
  grid-template-columns: 1fr 1fr;
  font-family: 'Roboto';
  column-gap: 70px;

  --main-offset-vertical: 110px;
  --button-scale: 0.8;

  .scrollbox{
    height: calc(100lvh - var(--size-header) - var(--main-offset-vertical));
    overflow-y: overlay;
    overflow-x: hidden;
    padding-right: 10px;
    margin-top: 20px;
    padding-top: 5px;
    padding-left: 5px;
  }

  .tabs{
    .tab{
      font-size: 16px;
      font-weight: 500;
      color: $text-grey;
      vertical-align: middle;
      margin-right: 36px;
      cursor: pointer;
      padding-bottom:2px;

      img{
        vertical-align: middle;
        width:20px;
        height: 20px;
        margin-right: 6px;
      }

      span{
        vertical-align: middle;
      }

      &.active{
        border-bottom: 2px solid #4cad82;
      }

      &:hover, &.active{
        color: $text-green1;
      }
    }
  }

  .context-menu{
    padding: 10px 15px;
    background: white;
    border-radius: 8px;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.12);
    z-index: 100;
    white-space: nowrap;

    img{
      vertical-align: middle;
      margin-right: 10px;
    }

    a {
      margin: 5px 0;
      display: block;
      text-decoration: none;
      color: black;
    }
  }

  h2{
    margin: 0;      
    font-weight: bold;
    padding-left: 5px;
  }

  .session{    
    --card-padding: 12px;

    height: 132px;
    margin-bottom: 24px;
    border-radius: 8px;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.12);
    background-color: #fff;
    display: flex;
    position: relative;
    overflow: hidden;

    &:last-child
    {
      margin-bottom: 5px;
    }

    button.dots{
      width: 16px;
      min-width: 16px;
      height: 16px;
      background: url(../assets/tree_dots.svg) no-repeat center;
      background-size: contain;
      border: none;
      position: absolute;
      right: 10px;
      top: 16px;
      padding: 0;
      z-index: 5;
    }

    &.past{
      height: 108px;
      .thumbnail{
        height: 108px;
      }      
    }

    .thumbnail{
      width: 132px;
      height: 132px;
    }

    &.practice{
      .thumbnail{
        filter: grayscale(1);
      }
    }

    .details{
      flex-grow: 1;
      padding: var(--card-padding);
      position: relative;

      table {
        margin-top: 8px;
        tr{
          font-weight: bold;
          font-size: 12px;
        }
        span{
          vertical-align: middle;
          &.light{
            color: #585c5e;
            font-size: 14px;
            font-weight: normal;
            display: block;
            padding-right: 5px;
            padding-top: 2px;
          }
        }
        img {
          width: 16px;
          height: 16px;
          vertical-align: middle;
        }
      }

      .title{
        height: 16px;
        font-size: 14px;
        line-height: normal;
        max-width: 380px;
        overflow: hidden;
        padding-right: 110px;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .status{
        font-size: 12px;
        text-align: right;
        color: $text-grey;
        position: absolute;
        right: 40px;
        top: 12px;
      }

      .date{
        margin-top: 3px;
        font-size: 12px;
        color: #585c5e;
        white-space: pre;
      }

      .links{
        position: absolute;
        left: var(--card-padding);
        bottom: var(--card-padding);
        right: var(--card-padding);
        a {
          text-decoration: none;          
          font-weight: 500;
          vertical-align: middle;
          color: #4cad82;
        }
        img {
          width: 16px;
          height: 16px;
          vertical-align: middle;
        }

        button{          
          position: absolute;
          right: 0;
          bottom: 0;   
          height: 25px;   
          // transform: scale(var(--button-scale));
          // transform-origin: right bottom;
        }
      }
    }

    &.request{
      height: unset;
      min-height: 88px;
      padding: var(--card-padding);
      display: flex;
      gap: 15px;
      overflow: unset;

      button.admin-button{
        height: 25px;
        position: absolute;
        right: var(--card-padding);
        bottom: var(--card-padding);
        min-width: 80px;
        // transform: scale(var(--button-scale));
        // transform-origin: right bottom;
      }

      .message{
        position: absolute;
        right: 56px;

        span{
          display: none;
          position: absolute;
          right: 30px;
          top: -5px;
          padding: 12px;
          border-radius: 8px;
          box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
          border: solid 1px #d7d7d8;
          background-color: #fff;
          //max-width: 80%;
          width: 300px;
        }
        &:hover{
          span{
            display: block;            
            z-index: 10;
          }
        }
      }

      .details{
        padding: 0;
        flex-grow: 1;
        margin-right:80px;
        // display: grid;
        //grid-template-columns: 82px 1fr 1fr 65px;
        align-items: center;
        // gap: 15px;        
        position: relative;

        &.warning{ 
          .date img{
            width: 16px;
            height: 16px;
            position: absolute;
            // left: -15px;
            margin-left: 10px;
            top: calc(50% - 8px);
          }
        }

        .workflow{
          width: 82px;
          //height: 24px;
          height: 20px;
          //line-height: 22px;
          line-height: 20px;
          text-align: center;
          //font-size: 14px;
          font-size: 12px;
          border-style: solid;
          border-width: 1px;
          border-radius: 2px;
          color: #195dcf;
          border-color: #195dcf;
          position: absolute;
          left: 0;
          bottom: 0;

          &.rejected{
            color: #ff4141;
            border-color: #ff4141;
            background-color: #fff0f0;
          }
          &.approved{
            color: #000;
            border-color: #000;
          }
        }
        .title{          
          padding: 0;
          height: unset;
        }
        .owner, .date{          
          color: #585c5e;
          white-space: pre;          
          position: relative;

          span{
            //padding: 0 5px 0 10px;
          }
        }
      }
    }
  }

  button.schedule{
    position: absolute;
    top: -5px;
    right: 12px;
  }

  @media (min-width: 1440px) {     
    column-gap: 80px;
    --button-scale: 0.73;
    
    .session {
      height: 140px;

      .details{ 
        .title{
          font-size:15px;
          height: 18px;
        }
        .status{
          top: 14px;
        }
        table {
          margin-top: var(--card-padding);
        }
      }
      .thumbnail{
        width: 140px;
        height: 140px;
      }

      &.past{
        height: 123px;
        .thumbnail{
          height: 123px;
        }      
      }
    }
  }

  @media (min-width: 1600px){  
    column-gap: 90px;
    --button-scale: 0.83;
    
    .session{ 
      height: 156px;
      --card-padding: 14px;

      .details{ 
        
        .title{
          font-size:16px;
          height: 20px;
          top: 16px;
        }
        .date{
          font-size: 13px;
        }
        table {
          tr{
            font-size: 13px;
          }
        }
        .links a{
          font-size: 14px;
        }
      }
      .thumbnail{
        width: 156px;
        height: 156px;
      }

      &.past{
        height: 138px;
        .thumbnail{
          height: 138px;
        }      
      }

      &.request{
        min-height: 94px;        
      }
    }
  }

  @media (min-width: 1920px){  
    --main-offset-vertical: 130px; 
    --button-scale: 1;   
    column-gap: 110px;
    
    .session{ 
      height: 188px;
      --card-padding: 16px;

      .details{ 
        .title{
          font-size:20px;
          height: 20px;
          top: 16px;
        }
        .status{
          top: 16px;
          font-size: 14px;
        }
        .date{
          font-size: 16px;
        }
        table {
          tr{
            font-size: 14px;
          }
        }
        .links{
          a{
            font-size: 16px;
          }

          button{
            height: 30px;
          }

          img {
            width: 20px;
            height: 20px;
          }
        }
      }
      .thumbnail{
        width: 188px;
        height: 188px;
      }

      &.past{
        height: 160px;
        .thumbnail{
          height: 160px;
        }      
      }

      &.request{
        min-height: 112px;

        button.admin-button{
          height: 30px;
        }

        .details{
          .workflow{
            height: 24px;
            line-height: 24px;
          }
        }
      }

      button.dots{
        width: 20px;
        height: 20px;
      }
    }
  }
}

</style>
